<template>
  <vx-card :title="title">

    <div class="vx-row mb-6">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
      >Back
      </vs-button>
    </div>

    <vs-tabs :value="active">
      <vs-tab label="Scrap">
        <Scrap></Scrap>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>

import Scrap from "./_tab/Import.vue"

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    Scrap
  },
  data() {
    return {
      title: "Scrap Import",
    };
  },
  methods: {
    handleBack() {
      this.$vs.loading();
      this.$router.go(-1);
      this.$vs.loading.close();
    },
  }
};
</script>
